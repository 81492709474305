@import './vars'; // ruta del archivo de variables
//* Tailwind Base --
@tailwind base;

//* Tailwind Components --
@tailwind components;

//* Tailwind Utilities --
@tailwind utilities;


/*
 * Tailwind custom
*/
.before-total {
    @apply before:absolute before:top-0 before:left-0 before:w-full before:h-[100%]
}
.overlay-total {
    @apply absolute top-0 left-0 w-full h-full;
}
.input_group {
    @apply relative w-full py-4;
    label {
        @apply text-base m-0 mb-1 block p-0;
    }
    input, select { border: 1px solid #F1F1F1; border-radius: 8px; line-height: normal; display: block; padding: 10px 15px; width: 100%;
        @apply text-base;
        &:focus { outline: 2px solid $primary-color; }
    }
    .input_hide {
        @apply absolute top-1/2 right-2 -translate-y-1/2 text-base w-[32px] h-[32px] flex items-center justify-center rounded-full bg-white cursor-pointer;
    }
}
.hide_text-oneline {
    @apply whitespace-nowrap text-ellipsis overflow-hidden max-w-full;
}
.header-toolbar-apply {
    @apply bg-black bg-opacity-70 absolute top-0 left-0 w-full h-auto z-20
}
.alert {
    @apply text-center rounded font-medium px-3 py-2 text-lg;
}
.alert-danger {
    @apply bg-red-200 text-red-500;
}
.alert-success {
    @apply bg-green-200 text-green-500;
}
.checkbox_toggle {
    .checkbox_toggle-content .checkbox_toggle-check {
        @apply translate-x-[5px];
        &.checked {
            @apply bg-green-400 translate-x-[30px];
        }
    }
}

.card_options { @apply w-full block text-white rounded-lg p-4 bg-gray-400 shadow-[0px_2px_6px_0px_rgba(0,0,0,0.20)];
    .img_content { @apply block relative w-[28px] before:block before:pb-[100%];
        img { @apply w-[85%] h-[85%] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 object-contain object-center;
            &.full_img { width: 100%; height: 100%; }
        }
        i { @apply absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2; font-size: 24px; }
    }
    h5 { @apply text-base leading-none m-0 mt-2 block font-light; }
    &:nth-child(1) { background-color: $second-color; }
    &:nth-child(2) { background-color: $third-color; }
    &:nth-child(3) { background-color: $fourth-color; }
}

.btn-default { @apply text-white bg-[color:var(--primary-color)] hover:bg-[color:var(--primary-color)] focus:ring-4 focus:outline-none focus:ring-[color:rgba(var(--rgb-primary-color),0.3)] font-medium rounded-[5rem] text-lg px-5 py-4 text-center inline-flex gap-3 justify-center items-center;
    &.btn-loading {
        &.show-loading { @apply pointer-events-none;
            span { @apply hidden; }
            .loader { @apply inline-flex items-center justify-center; }
        }
        .loader { @apply hidden origin-center scale-[1.4];
            i { --fa-animation-duration: 1.3s; }
        }
    }
    &.btn-small { @apply text-[15px] p-2; }
    &:disabled { background: #d7d7d7; pointer-events: none; }
    &.btn-transparent { background: transparent; color: var(--second-color); }
}
.button__select { @apply text-[color:var(--primary-color)] md:text-2xl text-xl block w-full relative before:block before:pb-[100%];
    ion-icon { @apply absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2; }
    ion-select { position: absolute; top: 0; left: 0; width: 100%; height: 100%; padding: 0; margin: 0; cursor: pointer; opacity: 0; }
}

.btn-light-default { @apply text-[color:var(--primary-color)] bg-transparent border-2 border-[color:var(--primary-color)] hover:text-white hover:bg-[color:var(--primary-color)] focus:ring-4 focus:outline-none focus:ring-[color:rgba(var(--rgb-primary-color),0.3)] font-medium rounded-[5rem] text-lg px-5 py-4 text-center inline-flex gap-3 justify-center items-center;
    &.btn-loading {
        &.show-loading { @apply pointer-events-none;
            span { @apply hidden; }
            .loader { @apply inline-flex items-center justify-center; }
        }
        .loader { @apply hidden origin-center scale-[1.4];
            i { --fa-animation-duration: 1.3s; }
        }
    }
    &.btn-small { @apply text-[15px] p-2; }
    &:disabled { background: #d7d7d7; pointer-events: none; border-color: #d7d7d7; color: #fff; }
}

.bg_lines { @apply absolute top-0 left-0 w-full h-full overflow-hidden pointer-events-none;
    &::before { content: ""; background-image: url('../../assets/img/frame-tl.svg'); @apply absolute top-0 left-0 bg-auto bg-left-top bg-no-repeat w-full h-full; }
    &::after { content: ""; background-image: url('../../assets/img/frame-br.svg'); @apply absolute bottom-0 right-0 bg-auto bg-right-bottom bg-no-repeat w-full h-full; }
    &.white {
        &::before { background-image: url('../../assets/img/frame-tl-white.svg'); }
        &::after { background-image: url('../../assets/img/frame-br-white.svg'); }
    }
    &.big {
        &::before { background-size: 30%; }
        &::after { background-size: 30%; }
    }
}
.status__label { @apply text-gray-500;
    &.pending { @apply text-amber-500; }
    &.cancelled, &.observed { @apply text-red-500; }
    &.expired { @apply text-indigo-500; }
    &.accepted, &.confirmed { @apply text-emerald-500; }
    &.\33 ds { @apply text-blue-500; } // equivalente a ".3ds"
    &.process { @apply text-lime-500; }
}

.status__color { @apply bg-gray-500;
    &.pending { @apply bg-amber-500; }
    &.cancelled, &.observed { @apply bg-red-500; }
    &.expired { @apply bg-indigo-500; }
    &.accepted, &.confirmed { @apply bg-emerald-500; }
    &.\33 ds { @apply bg-blue-500; } // equivalente a ".3ds"
    &.process { @apply bg-lime-500; }
}

.toast-container { padding: 10px; max-width: 100%;
    &.toast-top-right { top: 0; right: 0; }
    &.toast-top-left { top: 0; left: 0; }
    &.toast-bottom-right { bottom: 0; right: 0; }
    &.toast-bottom-left { bottom: 0; left: 0; }
    div[custom-toast-component], .ngx-toastr { border: 0; max-width: 100%; background: transparent !important;
        @apply shadow-none mt-0 ml-0 mr-0 mb-2 p-0 overflow-auto drop-shadow-[0_3px_5px_rgba(100,100,100,0.5)];
        &.toast-error { @apply text-gray-700;
            .toaster { @apply bg-red-100;
                .toast-icon { @apply bg-red-400; }
            }
            .close-icon { @apply text-red-400; }
        }
        &.toast-success { @apply text-gray-700;
            .toaster { @apply bg-green-100;
                .toast-icon { @apply bg-green-400; }
            }
            .close-icon { @apply text-green-400; }
        }
        &.toast-info { @apply text-gray-700;
            .toaster { @apply bg-sky-100;
                .toast-icon { @apply bg-sky-400; }
            }
            .close-icon { @apply text-sky-400; }
        }
        &.toast-warning { @apply text-gray-700;
            .toaster { @apply bg-yellow-100;
                .toast-icon { @apply bg-yellow-400; }
            }
            .close-icon { @apply text-yellow-400; }
        }
        .toaster { @apply grid grid-cols-[minmax(0,50px)_minmax(0,1fr)_minmax(0,25px)] pr-2 text-white bg-[#333333] w-[340px] max-w-full min-h-[80px] rounded-lg shadow-none m-0 overflow-hidden;
            .toast-icon { height: 100%; display: flex; align-items: center; justify-content: center; font-size: 26px;
                @apply row-span-2;
            }
            .toast-details { @apply text-base p-4 text-left; }
            .close-icon { @apply self-center;
                a { color: currentColor; @apply relative top-[unset] bottom-[unset] left-[unset] right-[unset] block m-0 w-full text-center float-none; }
            }
            .toast-details { @apply text-gray-700;
                .toast-title { @apply text-base font-semibold; }
                .toast-message { @apply text-sm; }
            }
            .progress-content { @apply col-start-2 col-end-3 relative block w-full; }
        }
    }
}
