@import url('../../assets/xtras/bootstrap-grid/bootstrap-grid.css');
@import '~../styles/vars'; // ruta del archivo de variables

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

// Angular Material
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

@import '~ngx-toastr/toastr';


// /* Basic CSS for apps built with Ionic */
// @import "~@ionic/angular/css/normalize.css";
// @import "~@ionic/angular/css/structure.css";
// @import "~@ionic/angular/css/typography.css";
// @import '~@ionic/angular/css/display.css';

// /* Optional CSS utils that can be commented out */
// @import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
// @import "~@ionic/angular/css/text-alignment.css";
// @import "~@ionic/angular/css/text-transformation.css";
// @import "~@ionic/angular/css/flex-utils.css";

// Init
$font-path: "../../assets/fonts";
// ICONS CAP
@import url('#{$font-path}/font-awesome/all.css');
@import url('#{$font-path}/linearicon/linearicon.css');
@import url('#{$font-path}/themify/themify.css');

// Font Family
/*--Fonts--*/
// @import url('#{$font-path}/font_montserrat/font_montserrat.css');
// @import url('#{$font-path}/font_roboto/font_roboto.css');


// @import url('https://fonts.googleapis.com/css2?family=Economica:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face{
    font-display:swap;font-family:'SamsungOne';
    font-style:normal;font-weight:normal;
    src:local('SamsungOne'),url('#{$font-path}/Samsung/SamsungOne-400.woff2') format('woff2'),
    url('#{$font-path}/Samsung/SamsungOne-400.woff') format('woff'),
    url('#{$font-path}/Samsung/SamsungOne-400.ttf') format('truetype'),
    url('#{$font-path}/Samsung/SamsungOne-400.eot?#iefix') format('embedded-opentype')
}
@font-face{
    font-display:swap;font-family:'SamsungOne';
    font-style:normal;font-weight:bold;
    src:local('SamsungOne'),
    url('#{$font-path}/Samsung/SamsungOne-700.woff2') format('woff2'),
    url('#{$font-path}/Samsung/SamsungOne-700.woff') format('woff'),
    url('#{$font-path}/Samsung/SamsungOne-700.ttf') format('truetype'),
    url('#{$font-path}/Samsung/SamsungOne-700.eot?#iefix') format('embedded-opentype')
}
@font-face{
    font-display:swap;font-family:'SamsungSharpSans';
    font-style:normal;font-weight:bold;
    src:local('SamsungSharpSans'),
    url('#{$font-path}/Samsung/SamsungSharpSansBd.woff2') format('woff2'),
    url('#{$font-path}/Samsung/SamsungSharpSansBd.woff') format('woff'),
    url('#{$font-path}/Samsung/SamsungSharpSansBd.eot?#iefix') format('embedded-opentype')
}
@font-face{
    font-display:swap;font-family:'SamsungSharpSans';
    font-style:normal;font-weight:normal;
    src:local('SamsungSharpSans'),
    url('#{$font-path}/Samsung/SamsungSharpSansBd.woff2') format('woff2'),
    url('#{$font-path}/Samsung/SamsungSharpSansBd.woff') format('woff'),
    url('#{$font-path}/Samsung/SamsungSharpSansBd.eot?#iefix') format('embedded-opentype')
}

body { font-family: $font-text; --ion-default-font: #{$font-text}; --ion-font-family: #{$font-text}; }

.font__title { font-family: $font-title; }
.font__subtext { font-family: $font-subtext; }
.font__text { font-family: $font-text; }

html, body { height: 100%; font-size: 100%; }


// * ionic >
* { -webkit-tap-highlight-color: transparent; }