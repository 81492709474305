$primary-color: #000000;
$second-color: #007BFF;
$third-color: #6C757D;
$fourth-color: #5d5d5d;

:root {
    --primary-color: #000000;
    --second-color: #007BFF;
    --third-color: #6C757D;
    --fourth-color: #5d5d5d;
    // for RGBA
    --rgb-primary-color: 0, 0, 0; //--primary-color *
    --rgb-second-color: 0, 123, 255; //--second-color *
    --rgb-third-color: 108, 117, 125; //--third-color *
    --rgb-fourth-color: 93, 93, 93; //--fourth-color *
}


$font-title: 'SamsungSharpSans', sans-serif;
$font-subtext: 'SamsungSharpSans', sans-serif;
$font-text: 'SamsungOne', sans-serif;