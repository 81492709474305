@import '~../../styles/vars'; // ruta del archivo de variables

//* MATERIAL COLOR DATEPICKER
.mat_form-type_1 {
    .mat-form-field-underline {
        display: none;
    }

    .mat-datepicker-toggle-active {
        color: $primary-color;
    }
}
.mat-datepicker-toggle {
  .mat-button-wrapper svg { margin: auto; }
}

.mat-calendar-body-selected {
    background-color: $primary-color;
}

.mat-calendar-body-in-range::before {
    background: rgba($primary-color, 0.2);
}

.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba($primary-color, 0.3);
}

// Animate CSS
@import url('../../styles/animate.css');

.animate__init {
    animation-delay: 0.3s;
}

.loader__page {
    width: 40px;
    height: 40px;
    position: relative;
    margin: auto;

    &.loader_one {

        &::before,
        &::after {
            content: '';
            position: absolute;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            top: calc(50% - 15px);
            left: calc(50% - 15px);
            animation-duration: 3s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
        }

        &::before {
            border-style: solid;
            border-width: 3px 3px 3px 0;
            border-color: $primary-color transparent transparent;
            transform: scale(0.3) rotate(0deg);
            opacity: 0.5;
            animation-name: foregroundCircle;
        }

        &::after {
            background: $primary-color;
            opacity: 0.5;
            transform: scale(0);
            animation-name: backgroundCircle;
        }
    }
}

@keyframes foregroundCircle {
    0% {
        transform: scale(0.3) rotate(0deg)
    }

    12.5% {
        transform: scale(0.3) rotate(180deg);
    }

    25%,
    50% {
        opacity: 1;
    }

    50% {
        transform: scale(1) rotate(720deg);
    }

    100% {
        transform: scale(0.3) rotate(1800deg);
        opacity: 0.5;
    }
}

@keyframes backgroundCircle {
    12.5% {
        transform: scale(0.3)
    }

    90%,
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

.swiper-pagination-bullet-active {
    background: $primary-color;
}

.loader-line {
    width: 200px;
    height: 3px;
    position: relative;
    overflow: hidden;
    background-color: #ddd;
    margin: 100px auto;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.loading_line_content {
    position: relative;
    overflow: hidden;
    pointer-events: none;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -50%;
        height: 3px;
        width: 8%;
        background-color: $primary-color;
        -webkit-animation: line_loading 1.1s linear infinite;
        -moz-animation: line_loading 1.1s linear infinite;
        animation: line_loading 1.1s linear infinite;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
    }
}

@keyframes line_loading {
    0% {
        left: -30%;
    }

    50% {
        left: 20%;
        width: 80%;
    }

    100% {
        left: 100%;
        width: 100%;
    }
}

.loading_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999999999;

    &.not_available_network {
        .loading__content {
            width: 250px;

            .not_available_network_icon {
                width: 100%;
                display: block;
                text-align: center;
                font-size: 6rem;
                color: $primary-color;
                line-height: 1;
            }
        }
    }

    .backdrop_loading {
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .loading__content {
        display: block;
        width: auto;
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        padding: 1.5rem 2rem;
        border-radius: 15px;

        h3 {
            display: block;
            width: 100%;
            text-align: center;
            font-size: 1.5rem;
            font-weight: 700;
        }

        p {
            display: block;
            margin: 0;
            text-align: center;
            width: 100%;
            font-weight: 500;
            font-size: 16px;
        }
    }
}

// Loading Default
.loads-ellipsis {
    margin: 0 auto;
    display: block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: $primary-color;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);

        &:nth-child(1) {
            left: 8px;
            animation: lds-ellipsis1 0.6s infinite;
        }

        &:nth-child(2) {
            left: 8px;
            animation: lds-ellipsis2 0.6s infinite;
        }

        &:nth-child(3) {
            left: 32px;
            animation: lds-ellipsis2 0.6s infinite;
        }

        &:nth-child(4) {
            left: 56px;
            animation: lds-ellipsis3 0.6s infinite;
        }
    }
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

// Loading 2
.loadingio-spinner-dual-ball {
    width: 100px;
    height: 100px;
    display: inline-block;
    overflow: hidden;
    background: transparent;
}

.ldio-dualball div {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: 30px;
    left: 10px;
}

.ldio-dualball div:nth-child(1) {
    background: #e90c59;
    animation: ldio-dualball 1s linear infinite;
    animation-delay: -0.5s;
}

.ldio-dualball div:nth-child(2) {
    background: #46dff0;
    animation: ldio-dualball 1s linear infinite;
    animation-delay: 0s;
}

.ldio-dualball div:nth-child(3) {
    background: #e90c59;
    animation: ldio-dualball-o 1s linear infinite;
    animation-delay: -0.5s;
}

.ldio-dualball {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
}

.ldio-dualball div {
    box-sizing: content-box;
}

@keyframes ldio-dualball-o {
    0% {
        opacity: 1;
        transform: translate(0 0)
    }

    49.99% {
        opacity: 1;
        transform: translate(40px, 0)
    }

    50% {
        opacity: 0;
        transform: translate(40px, 0)
    }

    100% {
        opacity: 0;
        transform: translate(0, 0)
    }
}

@keyframes ldio-dualball {
    0% {
        transform: translate(0, 0)
    }

    50% {
        transform: translate(40px, 0)
    }

    100% {
        transform: translate(0, 0)
    }
}

@-webkit-keyframes rotating

/* Safari and Chrome */
    {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

:root {
    --rotate: 0deg;
    --color: #000000;
    --item-width: 10px;
    --item-height: 1px;
}

@mixin on-circle($item-count, $circle-size, $item-width, $item-height) {
    position: relative;
    width: $circle-size;
    height: $circle-size;
    padding: 0;
    border-radius: 50%;
    transform: rotate(210deg);
    padding: 20px;

    >* {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: $item-width;
        height: $item-height;
        $angle: (360 / $item-count);
        $rot: 0;

        @for $i from 1 through $item-count {
            &:nth-of-type(#{$i}) {
                animation: background 1s ease $i / $item-count + s infinite;
                transform: rotate($rot * 1deg) translate($circle-size / 2) rotate($i * $angle + deg) rotate($rot * -1deg);
            }

            $rot: $rot + $angle;
        }
    }
}

@keyframes background {
    from {
        background: var(--color);
    }

    to {
        background: transparent;
    }
}

.loader_in {
    @include on-circle($item-count: 40, $circle-size: 60px, $item-width: var(--item-width), $item-height: var(--item-height));
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    >div {
        transform-origin: top center;
        background: transparent;
    }
}

.loader_content {
    position: relative;
    width: 600px;
    max-width: 100%;
    display: block;
    margin: auto;
    border: 1px solid #ebebeb;
    background-color: #fff;

    &::before {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    .loader_in {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        opacity: 0;
        visibility: hidden;

        &.show_qr {
            opacity: 1;
            visibility: visible;
        }
    }
}

html {
    background: $primary-color;
}

body.webapp-site {
    position: relative;
    color: #1C1C1C;
    background: $primary-color !important;

    // bg resaltado de color azul al hacer click en un "button" o "a"
    &.scanner-active {
        --background: transparent !important;
        --ion-background-color: transparent !important;
        background: transparent !important;

        ion-app.main-site {
            background: transparent !important;
        }
    }

    app-root,
    .root__page {
        display: block;
        height: 100%;
        width: 100%;
    }

    app-login,
    app-register,
    app-forgot-password {
        height: 100%;
    }

    .main-site {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        contain: unset;
        width: 100%;
        height: 100%;
        background-color: #F6F6F6;

        ion-router-outlet {
            position: unset;
            display: block;
            contain: unset;
            box-sizing: border-box;
            height: 100%;
            width: 100%;

            ion-app,
            .ion-page {
                height: 100%;
            }
        }

        ion-split-pane {
            --side-width: 280px;
        }

        router-outlet#main-content {
            display: contents;

            ion-app,
            .ion-page {
                height: 100%;
            }
        }

        ion-app {
            display: block;
            position: unset;
            width: inherit;
            height: inherit;
            contain: unset;
        }
    }

    .can-go-back .content_btn-back ion-back-button {
        display: initial;
    }

    ion-modal {
        &.success__modal {
            --height: 100%;
        }

        &.middle__modal {
            --width: fit-content;
            --min-width: 250px;
            --max-width: calc(100% - 1rem);
            --max-height: 100%;
            --height: fit-content;
            --border-radius: 6px;
            --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
            --overflow: hidden auto;

            .wrapper {
                padding: 1.5rem;
                position: relative;
            }
        }

        &.modal__sheet {
            --border-radius: 10px 10px 0 0;

            &.ios {
                --border-radius: 10px;
            }

            // .ion-page { height: auto; }
            .wrapper {
                padding: 1.5rem;
                position: relative;
            }
        }
    }

    ion-alert {
        .alert-wrapper {
            .alert-button-group {
                .alert-button {
                    --ion-color-primary: var(--primary-color);
                }
            }
        }
    }

    ion-action-sheet {
        .action-sheet-wrapper {
            .action-sheet-container {
                .action-sheet-group:not(.action-sheet-group-cancel) {
                    padding: 1rem;

                    button {
                        --button-background: transparent;
                        --button-background-selected: transparent;
                        --button-color: #1C1C1C !important;
                        color: #1C1C1C !important;
                        border-radius: 8px;

                        &.action-sheet-selected {
                            background: $primary-color;
                            color: #fff !important;
                            --button-color: #fff !important;
                        }
                    }
                }

                .action-sheet-group.action-sheet-group-cancel button {
                    color: $primary-color !important;
                    --button-color: $primary-color !important;
                }
            }
        }
    }

    .content_menu-items {
        display: flex;
        flex-flow: column;
        gap: 1.5rem;
        width: 100%;
        /*  padding: 1rem; */

        ion-menu-toggle {
            width: 100%;
            display: block;

            button {
                padding: 0.8rem 1rem 0.8rem 2rem;
                display: grid;
                grid-template-columns: minmax(0, 16px) minmax(0, 1fr);
                gap: 0.5rem;
                align-items: center;
                line-height: normal;
                width: 100%;
                text-align: left;
                cursor: pointer;
                font-weight: 400;
                font-size: 15px;
                color: #4B4B4B;
                transition: all ease-in-out 0.2s;

                &:hover {
                    color: $primary-color;
                }

                span {
                    display: inline-block;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        .group__items-menu {
            width: 100%;

            h4 {
                display: block;
                width: 100%;
                margin: 0;
                padding: 0 0 1rem;
                font-weight: 500;
                font-size: 18px;
                color: #4B4B4B;
            }

            .group__items {
                /* padding: 0 1rem; */
                display: flex;
                flex-flow: column;
                /* gap: 0.5rem; */
                width: 100%;
            }
        }
    }

    .form__inputs-date {
        .mat-form-field-infix {
            pointer-events: none;
        }
    }

    .hidden_two-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .swiper_type2 {
        .swiper-pagination {
            position: absolute;
            bottom: unset;
            top: 10px;
        }
    }

    .content_invoices {
        width: 100%;
        height: auto;

        .invoice {
            position: relative;
            display: block;

            input[type="radio"],
            input[type="checkbox"] {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                appearance: none;
                opacity: 0;
                z-index: 2;
                cursor: pointer;

                &:checked~.detail_invoice {
                    background-color: $primary-color;
                    color: #fff;
                }
            }

            mat-checkbox {
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 0;
                pointer-events: none;
                opacity: 0;
                visibility: hidden;
            }

            .check_invoice {
                display: none;
            }

            .detail_invoice {
                display: flex;
                flex-flow: column;
                gap: 0.8rem;
                border: 1px solid #ddd;
                padding: 1.5rem;
                border-radius: 10px;
                font-size: 13px;
                cursor: pointer;

                &.no_pay {
                    background-color: #f4f3f3;
                    pointer-events: none;
                }

                &.checked_item {
                    background-color: $primary-color;
                    color: #fff;
                }

                h4 {
                    font-size: 16px;
                    font-weight: 600;
                    margin: 0;
                }

                p {
                    font-size: 14px;
                    margin: 0;
                }
            }
        }
    }

    .content_segment ion-segment {
        --background: #fff;

        ion-segment-button {
            --indicator-box-shadow: transparent !important;
            --border-radius: 2rem !important;
            padding: 0.5rem 0.1em !important;
            --color: #b4b3b3;
            --color-checked: var(--primary-color) !important;
            --indicator-color: var(--primary-color) !important;
            --min-height: 0 !important;

            &::part(indicator) {
                height: 2px;
                position: absolute;
                top: unset;
                bottom: 0;
                left: 0;
                width: 100%;
            }

            &.seg_pending {
                --color-checked: #F78F14 !important;
                --indicator-color: #F78F14 !important;
            }

            &.seg_approved {
                --color-checked: #00B376 !important;
                --indicator-color: #00B376 !important;
            }

            &.seg_rejected {
                --color-checked: #333333 !important;
                --indicator-color: #333333 !important;
            }

            ion-label {
                font-size: 15px !important;
                font-weight: 500;
            }
        }
    }

    .status_item {
        background-color: #dedede;

        &.pending {
            background-color: #F78F14 !important;
        }

        &.approved {
            background-color: #00B376 !important;
        }

        &.rejected {
            background-color: $primary-color !important;
        }
    }

    .payment_status {
        span {
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-size: contain;
            -webkit-mask-position: center;
            mask-size: contain;
            mask-position: center;
            background-color: #000;
        }

        &.pending {
            color: #F78F14;

            span {
                background-color: #F78F14 !important;
            }
        }

        &.approved {
            color: #00B376;

            span {
                background-color: #00B376 !important;
            }
        }

        &.rejected {
            color: $primary-color;

            span {
                background-color: $primary-color !important;
            }
        }
    }

    .accordion_style {
        mat-expansion-panel {
            padding: 0;
            padding-top: 0.5rem;
            margin: 0 !important;
            box-shadow: none;
            background: transparent;
            border-radius: 0;

            &.mat-expansion-panel-spacing {
                margin: 0 !important;
            }

            &.mat-expanded {
                mat-expansion-panel-header .icon__dropdown img {
                    transform: rotate(90deg);
                }
            }

            mat-expansion-panel-header {
                padding: 0 !important;
                padding-bottom: 0.5rem !important;
                background: transparent !important;

                .icon__dropdown img {
                    width: auto;
                    max-width: 100%;
                    display: block;
                    margin: auto;
                    transition: all ease-in-out 0.25s;
                    transform: rotate(0deg);
                }
            }

            .mat-expansion-panel-body {
                padding: 0 !important;
            }
        }
    }

    .form_ion_normal {
        .item__input {
            ion-item {
                display: block;
                width: 100%;
                --background: transparent;
                --padding-start: 0;
                --inner-padding-end: 0;
                --border-width: 0 0 0 0;
                --show-full-highlight: 0;
                --highlight-background: transparent;

                &::part(native) {
                    padding: 0;
                }

                ion-select {
                    max-width: 100%;

                    &::part(placeholder) {
                        padding: 0 15px 0 0;
                    }
                }
            }
        }
    }

    .form__group {
        padding: 0 0 0.7rem 0;
        width: 100%;

        .item__input {
            width: 100%;
            display: block;

            label {
                font-size: 16px;
                font-weight: 400;

                &.required {
                    &::after {
                        content: " *";
                        color: red;
                    }
                }
            }

            ion-select {
                max-width: 100%;
                width: 100%;
                padding: 0.7rem 1rem;
                background: #F9F9F9;
                border-radius: 5px;

                &::part(placeholder),
                &::part(text) {
                    padding: 0 15px 0 0;
                    font-size: 15px;
                    line-height: 17.58px;
                    min-height: 17.58px;
                }
            }

            input:not([type="checkbox"]),
            input:not([type="radio"]),
            .input_div {
                padding: 0.7rem 1rem;
                font-size: 15px;
                line-height: 17.58px;
                width: 100%;
                display: block;
                background: #F9F9F9;
                border-radius: 5px;
                outline: none;
            }

            &[disabled="true"] {

                input,
                ion-select,
                .input_div {
                    background-color: #efefef;
                    pointer-events: none;
                }
            }
        }

        .item__btn {
            padding: 0;
            padding-top: 1rem;
            width: 100%;
            display: block;
        }

        .input_content {
            input {
                pointer-events: none;
            }

            &.type_field {
                padding: 1rem 1.5rem;
                background: #F9F9F9;
                /* border: 1px solid #d9d9d9;*/
                border-radius: 10px;
                /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.10); */
                display: flex;
                flex-flow: column;
                gap: 0.5rem;
                width: 100%;

                h5 {
                    font-size: 14px;
                    font-weight: 600;
                    margin: 0;
                    line-height: 1.6;

                    small,
                    span {
                        font-size: 14px;
                        font-weight: 300;
                    }
                }
            }

            .invoices__items {
                display: flex;
                flex-flow: column;
                width: 100%;
                gap: 0;

                .invoices__item {
                    width: 100%;
                    padding: 10px;

                    &:not(:last-child) {
                        border-bottom: 1px solid #ddd;
                    }
                }
            }
        }
    }

    .swal2-container {
        .swal2-popup {
            .swal2-icon {
                width: 4em;
                height: 4em;

                .swal2-icon-content {
                    font-size: 2.75em;
                }
            }

            .swal2-title {
                font-size: 22px;
            }

            .swal2-html-container {
                font-size: 16px;
            }

            .swal2-actions {
                .swal2-confirm {
                    background-color: $primary-color;

                    &:focus {
                        box-shadow: 0 0 0 3px rgba($primary-color, 0.50);
                    }
                }

                .swal2-styled {
                    font-size: 16px;
                }
            }
        }
    }

    .text-two-hidden {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    // Qhantuy Empresa App
    ion-alert {
        .alert-title {
            font-size: 20px;
        }

        .alert-message {
            font-size: 16px;
        }
    }

    ion-select {
        &::part(icon) {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
        }

        &::part(text) {
            position: relative;
            display: block;
            width: 100%;
            padding: 0 15px 0 0;
        }
    }

    ion-toolbar {
        --min-height: unset;
    }

    // ion-menu { --width: 100%; }
    .hide_scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .content_btn-back {
        /* width: 40px; position: relative; */
        display: flex;
        align-items: center;
        gap: 1rem;

        // &::before { content: ""; display: block; padding-bottom: 100%; }
        ion-back-button {
            min-width: unset;
            max-width: 100%;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;

            &::part(native) {
                background-color: $primary-color;
                border-radius: 4px;
                color: #fff;
            }
        }
    }

    .badge {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: auto;
        min-width: 30px;
        height: 30px;
        border-radius: 10rem;
        padding: 0 5px;
        background-color: $primary-color;
        color: #fff;
    }

    .content_tabs {
        text-align: center;
        width: 100%;
        z-index: 12;
        display: block;
        max-width: 500px;
        margin: 0 auto;

        .content_tabs-wrapper {
            width: 100%;
            display: grid;
            grid-template-columns: minmax(0, 1.8fr) minmax(0, 1fr) minmax(0, 1.8fr);
            filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15));

            .tab_two {
                width: 100%;
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                background: #fff;
                color: #4B4B4B;

                &:first-child {
                    border-top-right-radius: 10px;
                    padding-left: 10px;
                }

                &:last-child {
                    border-top-left-radius: 10px;
                    padding-right: 10px;
                }

                .tab_int {
                    padding: 1rem 0.25rem;
                    position: relative;

                    &.notifications::before {
                        content: "";
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        width: 5px;
                        height: 5px;
                        border-radius: 100%;
                        background-color: $primary-color;
                    }

                    &.active {
                        .overlay {
                            opacity: 0.1;
                        }
                    }

                    .overlay {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) scaleX(0.9) scaleY(0.85);
                        opacity: 0;
                        z-index: 0;
                        transition: all ease-in-out 0.3s;
                        background: #000;
                        border-radius: 8px;
                    }

                    .content_i-tab {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 30px;
                        margin: 0 auto;
                        height: auto;
                        position: relative;
                        z-index: 1;

                        &::before {
                            content: "";
                            display: block;
                            padding-bottom: 100%;
                        }

                        &.big_img-tab {
                            img {
                                transform: translate(-50%, -50%) scale(1.2);
                            }
                        }

                        i {
                            font-size: 24px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }

                        img {
                            /* width: 100%;
                            height: 100%; */
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            object-fit: contain;
                            object-position: center;
                        }
                    }

                    p {
                        margin: 8px 0 0;
                        display: block;
                        line-height: 1;
                        font-size: 10px;
                        font-weight: 300;
                        position: relative;
                        z-index: 1;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .tab_middle {
                width: 100%;
                position: relative;

                .bg_tab_middle {
                    mask-image: url('../../../assets/img/icons/srt.svg');
                    -webkit-mask-image: url('../../../assets/img/icons/srt.svg');
                    mask-position: center 0%;
                    -webkit-mask-position: center 0%;
                    mask-repeat: no-repeat;
                    -webkit-mask-repeat: no-repeat;
                    mask-size: 115%;
                    -webkit-mask-size: 115%;
                    background-color: #fff;
                    width: calc(100% + (8px * 2));
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    height: 100%;
                    transform: translateX(-50%);
                    z-index: -1;
                }

                .option_tab {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 75%;
                    border-radius: 50%;
                    background-color: $primary-color;
                    color: #fff;

                    &::before {
                        content: "";
                        display: block;
                        padding-bottom: 100%;
                    }

                    &.active_more-menu {
                        div {
                            i {
                                transform: rotate(134deg);
                            }
                        }
                    }

                    div {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;

                        i {
                            display: inline-block;
                            transition: all ease 0.3s;
                            font-size: 19px;
                            line-height: normal;
                        }

                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 100%;
                            height: 100%;
                            object-fit: scale-down;
                            object-position: calc(50% + 0px);
                        }
                    }
                }

                p {
                    position: absolute;
                    bottom: 0;
                    font-size: 10px;
                    margin: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding: 1rem 0.25rem;
                    line-height: 1;
                    font-weight: 300;
                    text-align: center;
                    width: 100%;
                    max-width: 100%;
                }
            }
        }

        .content-grid-options {
            display: none;
            max-width: 425px;
            position: absolute;
            bottom: 125px;
            left: 0;
            right: 0;
            width: calc(100% - 30px);
            margin: 0 auto 10px;
            border-radius: 20px;
            background-color: #fff;
            box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.30);

            &.active {
                display: block;
            }

            .content-optionts {
                padding: 20px 5px;

                .item-options {
                    display: flex;
                    flex-flow: column;
                    align-items: center;

                    i,
                    img {
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        font-size: 24px;
                        color: #8e8e8e;

                        &.img_icon {
                            background: #8e8e8e;
                            -webkit-mask-size: 72%;
                            mask-size: 72%;
                            -webkit-mask-repeat: no-repeat;
                            mask-repeat: no-repeat;
                            -webkit-mask-position: center;
                            mask-position: center;
                        }
                    }

                    img {
                        object-fit: contain;
                        object-position: center;
                    }

                    .round-i {
                        border-radius: 50%;
                        background-color: rgba($primary-color, 0.18);
                        font-size: 20px;
                        color: $primary-color;
                        /*& ~ label { color: var( --ion-color-primary ); }*/
                    }

                    label {
                        font-size: 12px;
                        margin-top: 5px;
                        color: #8e8e8e;
                        text-align: center;
                    }
                }
            }
        }
    }

    .input_radio-button {
        input {
            appearance: none;

            &:checked~div {
                background-color: $primary-color;
                color: #fff;
            }
        }

        div {
            background: transparent;
            color: $primary-color;
            border: 1px solid $primary-color;
        }
    }

    .scrollbar_css {
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 4px rgba(128, 128, 128, 0.34);
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: #04aa6d;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #05915e;
        }

        &.min_bar {
            &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 2px rgba(128, 128, 128, 0.34);
                border-radius: 6px;
            }
        }
    }

    .space__footer-scroll {

        .content_scroll-site,
        .ic__space {
            padding-bottom: 7rem;
        }
    }

    .content_splash-screen-cap {
        z-index: 9999999999999999999999999999;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $primary-color;
        display: flex;
        align-items: center;
        justify-content: center;

        &.hidden_splashscreen {
            display: none;
        }

        .mobile__splash-screen {
            width: 600px;
            display: block;
            margin: auto;
            max-width: 100%;
        }

        .mobile_lines::before,
        .mobile_lines::after {
            background-size: 200px;
        }
    }
}

/*
    * IOS
*/
.ios,
.plt-capacitor.plt-ios {
    body.webapp-site {
        .header_space-ios {
            padding-top: 1.5rem;
        }

        .content_tabs .content_tabs-wrapper {
            .tab_two {
                padding-bottom: 1rem;
            }

            .tab_middle p {
                padding-bottom: 2rem;
            }
        }
    }
}

.title-page {
    font-family: $font-title !important;
    font-weight: 700 !important;
    font-size: 20px !important;
}


.link-list {
    color: rgb(0, 123, 255);
    font-size: 16px !important;
    font-weight: 700 !important;
    font-family: $font-text !important;
}

.link-list-red {
    color: rgb(255, 4, 0);
    font-size: 16px !important;
    font-weight: 700 !important;
    font-family: $font-text !important;
}

.sub-title {
    color: #000000;
    font-size: 18px !important;
    font-weight: 700 !important;
    font-family: $font-text !important;
    margin-bottom: 8px !important;
}

.txt-parrafo {
    color: #6E6E6E;
    font-size: 16px !important;
    font-weight: 400 !important;
    font-family: $font-text !important;
    margin: 0px !important;
}

.text-data {
    color: #5a5a5a;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: $font-text !important;
    margin: 0px !important;
}

.stock {
    padding: 5px 12px !important;
    border-radius: 50px;
    color: white;
    margin: 0px !important;
    font-size: 13px !important;
    font-family: $font-text !important;
    font-weight: 700 !important;
}

.title-modal {
    font-size: 24px !important;
    font-weight: $font-title !important;
    font-weight: 700 !important;
    color: #000;
}

.progress-text {
    font-family: $font-text !important;
    font-size: 16px !important;
    color: #17A2B8;
    font-weight: 400 !important;
    text-align: center !important;
}

.number-text {
    font-size: 40px !important;
    font-weight: 700 !important;
    font-family: $font-title !important;
}

.progress-text--green {
    font-family: $font-text !important;
    font-size: 16px !important;
    color: #28A745;
    font-weight: 700 !important;
}

.font__title {
    font-family: $font-title !important;
}

.number-text--thin {
    font-size: 28px !important;
    font-weight: 300 !important;
    font-family: $font-text !important;
}

.ngx-charts-outer {
    height: auto !important;
}

.chart-legend .legend-labels {
    width: fit-content !important;
    padding: 10px 15px !important;
    background: none !important;
    margin-left: 25px !important;
}

.chart-legend .legend-label {
    padding: 10px;
    border: 1px solid rgb(216, 214, 214);
    border-radius: 10px;
    font-size: 14px !important;
}

.mat-form-field-underline {
    display: none;
}

.reading-block {
    overflow: visible !important;
    font-weight: 700 !important;
}

.reading-affix {
    font-weight: 700 !important;
}

.point {
    width: 14px;
    height: 14px;
    border-radius: 5px;
    display: inline-block;
}

table {
    width: 100%;
    text-align: center;
}

.mat-elevation-z8 {
    box-shadow: none !important;
}


th.mat-header-cell,
td.mat-cell {
    padding-left: 0px !important;
    text-align: center !important;
}

th.mat-header-cell {
    font-size: 14px !important;
    font-weight: 700 !important;
    color: #000 !important;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
    border-radius: 5px;
}

input.mat-input-element {
    padding: 0.7rem 1rem;
}

.wrapper__checkmark {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 10px;

    .checkmark {
        width: 125px;
        height: 125px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: #fff;
        stroke-miterlimit: 10;
        margin: 10% auto;
        box-shadow: inset 0px 0px 0px #7ac142;
        animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;

        .checkmark__circle {
            stroke-dasharray: 166;
            stroke-dashoffset: 166;
            stroke-width: 2;
            stroke-miterlimit: 10;
            stroke: #7ac142;
            fill: none;
            animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
        }

        .checkmark__check {
            transform-origin: 50% 50%;
            stroke-dasharray: 48;
            stroke-dashoffset: 48;
            animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
        }
    }

    @keyframes stroke {
        100% {
            stroke-dashoffset: 0
        }
    }

    @keyframes scale {

        0%,
        100% {
            transform: none
        }

        50% {
            transform: scale3d(1.1, 1.1, 1)
        }
    }

    @keyframes fill {
        100% {
            box-shadow: inset 0px 0px 0px 100px #7ac142
        }
    }
}

/* .mat-mdc-form-field+.mat-mdc-form-field {
    margin-left: 8px;
} */

.mat-expansion-panel:not([class*=mat-elevation-z]) {
    box-shadow: none !important;
}

.mat-expansion-indicator::after {
    border: 2px solid #007BFF;
}

.bg__qr {
    background: url('/assets/img/img-cam.png');
    background-size: cover;
    background-position: center;
}


/*
    * Desktop Versión
*/
